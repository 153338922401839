import React from "react";

import './CastCrew.css'

import Natasha from './assets/natasha-headshot.png';
import Russell from './assets/russell-headshot.png';

import Aidan from './assets/nick-wojaks/Aidan Feels.png'
import Sierra from './assets/nick-wojaks/Sierra Neutral.png'


const CastBioSection = (props) => {
    return (
        <div className="container bio-div">

            <div className="row">
                <div className="col-12 bio-title">
                    <h3><span className="actor-name">{props.name}</span><span className="is-div"> is </span><span className="">{props.role}</span></h3>
                </div>
            </div>
            <div className="row">

                <div className="col-lg-3 bio-pic">
                    <img src={props.img} />
                </div>

                <div className="col-lg-6 bio-text">
                    <p><em>{props.text1}</em></p>
                    <p>{props.text2}</p>

                </div>

                <div className="col-lg-3 bio-pic">
                    <img src={props.wojak} />
                </div>
            </div>


        </div>
    )
}

const Cast = () => {
    return (
        <div id='TomiMiles'>

            <div className="container cast-section-header">
                <div className="row">
                    <h1>Starring</h1>
                </div>
            </div>

            <CastBioSection
                name='Natasha Mercado'
                role='Sierra'
                img={Natasha}
                wojak={Sierra}
                text1='Searching for something more out of life, Sierra quits her job to become a full time content creator. She quickly discovers that to get the most views she must jump head first into fan culture. Overwhelmed by the pressure to always be posting, she recruits fans to help her.'
                text2='Natasha Mercado is a Los Angeles-based performing artist whose work has been featured on Adult Swim, Más Mejor, TBS and HBO. She currently tours her award-winning solo show Tree, which Stage Raw has called one of “LA’s Must See Shows”. Born and raised in Maryland, she has been lucky enough to work with other artists from around the world who share a passion for connection, vulnerability and absurdity.'
                link=''
            />


            <CastBioSection
                name='Russell Sorbello'
                role='Aidan'
                img={Russell}
                wojak={Aidan}
                text1='Aidan is a basement-dwelling fanboy who, when he’s not watching movies and TV, is watching vloggers talk about movies and TV. His parasocial dream comes true when Sierra invites him to come film for her. '
                text2='Russell Sorbello is an actor and filmmaker based in Maryland. They graduated from Towson University with a degree in Theatre Studies in 2018. Their work as a writer director includes Wrong Arm of the Law (2017), Hello (2018), and Matched (2020) which have screened and won awards in Maryland, DC, Kentucky, Oregon, and New Jersey'
                link=''
            />

            <div className="container cast-section-header">
                <div className="row">
                    <h1>Featuring</h1>
                </div>
            </div>

            <div className="container bio-div">

                <div className="row">
                    <div className="col-12 bio-title">
                        <h3><span className="actor-name">Joshua Greene</span><span className="is-div"> is </span><span className="">Shia</span></h3>
                    </div>
                </div>
                <div className="row">



                    <div className="col-lg-9 bio-text">
                        <p><em>Shia started as just another one of Sierra’s fans, but ended up her boyfriend. Unable to show up as a partner like he could for a streamer, they break up right before the movie starts.</em></p>
                        <p>Joshua Greene is a multi-disciplinary artist within the video art and musical performance disciplines. Greene uses acting and modeling as tools for extending into ideals of persona. In the past, Greene has performed in NYC, Philadelphia, Baltimore, & Los Angeles with a planned 2023 tour under his moniker LunchBox President. He is originally from Delaware and is now operating out of Brooklyn, NY. </p>

                    </div>


                </div>


            </div>

            <div className="container bio-div">

                <div className="row">
                    <div className="col-12 bio-title">
                        <h3><span className="actor-name">Kamary Philips</span><span className="is-div"> is </span><span className="">Mr. Best</span></h3>
                    </div>
                </div>
                <div className="row">



                    <div className="col-lg-9 bio-text">
                        <p><em>Mr. Best is a jaded, washed up actor doing the rounds at fan conventions. He has a secret about the famous character he played that the big studios don’t want to get out.</em></p>
                        <p>Kamary Craig Phillips is an artist at heart and a content creator by trade. He has worked primarily in Europe as a freelancer over the years and as such has contributed to the success of numerous projects, productions and businesses, spanning through the Entertainment, Tech, and Mobility sectors.</p>

                    </div>


                </div>


            </div>


        </div>
    )
}

export default Cast;