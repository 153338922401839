import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import './Chatroom.css';

const ChatButton = (<button>LOLLLLL</button>);

const ChatDialogue = [
    'Hey, Did you see my new video?',
    'Well… Shia and I broke up',
    'For real this time like hes packing up rn',
    'It feels like everything is falling apart',
    'I have no idea what I’m going to do for ComicCon.',
    'The rave is Thursday, the fest is all weekend. I was going to shoot a months worth of content.',
    'I have a crazy idea',
    'Maybe you could come help me? ',
    'You could stay at my place. We could make the vids together!',
    '🥺',
    '🙏',
    'ChatButton',
];


const Chatroom = () => {
    const [counter, setCounter] = useState(1);

    const Typer = (j) => {
        const Typed = []
        for (let i = 0; i < j; i++) {
            if (i < 11) {
                Typed.push(<li className='normal-chat-line'><span className='sierra-username'>Sierrrra__: </span>{ChatDialogue[i]}</li>)
            } else {
                Typed.push(
                    <li>
                        <NavLink to='/Info'>                <span className='sierra-username'>Sierrrra__: </span><button className='chat-button'>Please can you help me?</button>
                        </NavLink>
                    </li>

                )
            }

        }
        return Typed;
    }

    const TypingDots = (test) =>{
        if (test < 11){
            return (<p>...</p>)
        } else {
            return (<p></p>)
        }

    }

    useEffect(() => {
        setTimeout(() => {
            setCounter((counter) => counter + ((counter < 12) ? 1 : 0));
        }, 1500);
    });

    return (
        <div>
            <ul className='chat-ul'>
                {Typer(counter)}
                {TypingDots(counter)}
            </ul>
            <ul className='chat-ul chatbox'>
                <li></li>
            </ul>

        </div>
    )

}



export default Chatroom;
