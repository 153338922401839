import React from "react";

import Button from "./Button";
import ButtonMobile from "./ButtonMobile";

import { NavLink } from "react-router-dom";

import './Navbuttons.css'

import Girlheart from './assets/icons/girlheart.png'
import Heartvines from './assets/icons/heart-vines-stripes.png'
import Heart from './assets/icons/heart.png'
import Lolly from './assets/icons/heart-lolly.png'
import Flying from './assets/icons/flying-heart-icon.webp'
import Hamburger from './assets/hamburger.png'


import Title from './assets/logo-color-final.png'

const toggleMenu = () => {
    let menu = document.getElementById('collapsible');
    let hamburger = document.getElementById('hamburgerDiv')

    if (menu.style.display === 'none') {
        console.log('nice');
        menu.style.display = 'block';
        hamburger.style.paddingBottom = '0px';

    } else {
        menu.style.display = 'none';
        hamburger.style.paddingBottom = '20px';
    }


}


const Navbuttons = () => {
    return (
        <div id='Navbuttons'>


            <ul className="button-ul desktop">
                <li>
                    <NavLink to='Landing'>
                        <div className="title-div">
                            <img src={Title} />
                        </div>
                    </NavLink>
                </li>
                <Button buttonimage={Lolly} dest='Info' text='Info' />
                <Button buttonimage={Girlheart} dest='News' text='News' />
                <Button buttonimage={Heartvines} dest='Filmmakers' text='Filmmakers' />
                <Button buttonimage={Heart} dest='Cast' text='Cast' />
                <Button buttonimage={Flying} dest='Contact' text='Contact' />
            </ul>

            <div className="menu-mobile">
            <div id="hamburgerDiv">
                <ul>
                    <li>
                    <div style={{textAlign: `center`}}>
                        <img
                            src={Hamburger}
                            alt='menu button'
                            style={{ width: `40px` }}
                            className='navBurger'
                            onClick={toggleMenu}
                        />
                    </div>
                    </li>
                </ul>
                    
                </div>
                <ul id='collapsible'>
                    <li>
                        <NavLink to='Landing'>
                            <div className="title-div">
                                <img src={Title} />
                            </div>
                        </NavLink>
                    </li>
                    <ButtonMobile buttonimage={Lolly} dest='Info' text='Info' />
                    <ButtonMobile buttonimage={Girlheart} dest='News' text='News' />
                    <ButtonMobile buttonimage={Heartvines} dest='Filmmakers' text='Filmmakers' />
                    <ButtonMobile buttonimage={Heart} dest='Cast' text='Cast' />
                    <ButtonMobile buttonimage={Flying} dest='Contact' text='Contact' />
                </ul>


            </div>

        </div>
    )

}

export default Navbuttons;