import React from "react";
import {NavLink} from 'react-router-dom'

const ButtonMobile = (props) => {
    const buttonimage = props.buttonimage;
    const dest = props.dest;
    const text = props.text
    return (
        <li>
            <div className="mobile-button">

                <NavLink to ={dest}>
                    <p className="mobile-button-text">                    {text}</p>
                </NavLink>
            </div>
        </li>
    )
}

export default ButtonMobile;
