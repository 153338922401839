import React from "react";
import './Trailer.css';

import Still1 from './assets/stills/1.png'
import Still2 from './assets/stills/2.png'
import Still3 from './assets/stills/3.png'
import Still4 from './assets/stills/4.png'
import Still5 from './assets/stills/5.png'
import Still6 from './assets/stills/6.png'
import Still7 from './assets/stills/7.png'
import Still8 from './assets/stills/8.png'
import Still9 from './assets/stills/9.png'



const Still = () => {
    return (
        <div className="still">
            <img src={Still1} />
        </div>
    )
}

const Trailer = () => {
    return (
        <div id='Trailer' className="container">

            <div className="row notes">
                NOTES: Trailer/stills/description goes here we can brainstorm page layout like where all the elements go etc I can try a few a different ways and see what looks cute
                <br /><br />
                <span className="holly">- Holly</span>
            </div>

            <div className="info-top-text glow-big">
                <p>A feature film about a fanboy, an e-girl, and the horrors of fandom</p>
            </div>

            <div className="row trailer-description">
                <div className="col trailer-padding">
                    <div style={{ padding: `30%`, position: `relative` }}>
                        <iframe src="https://player.vimeo.com/video/752745554?h=abbee36ea4"
                            style={{ position: `absolute`, top: `0`, left: `0`, width: `100%`, height: '100%' }}
                            allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>


            </div>

            <div className="row">
            <div className="col description">
                    <p>Aidan, an awkward fanboy, finally leaves his mother’s basement and goes to meet Sierra, a vlogger fresh off a break up who needs help filming a new video series at Comic-Con. They struggle to navigate the newly devirtualized relationship, and just as they’re starting to figure it out, disaster strikes. Further alienated and unable to cope, Aidan descends into nightmares, becoming a monstrous toy as he dawns Sierra's internet identity and forges on to Comic-Con to complete the video series.
                    </p>
                </div>
            </div>

{/*             <div className="row trailer-stills-row">



                <div className="container">

                    <div className="stills row">
                        <div className='col-sm-4'>
                            <img src={Still1} />
                        </div>
                        <div className='col-sm-4'>
                            <img src={Still2} />
                        </div>
                        <div className='col-sm-4'>
                            <img src={Still3} />
                        </div>
                    </div>

                    <div className="stills row">
                        <div className='col-sm-4'>
                            <img src={Still4} />
                        </div>
                        <div className='col-sm-4'>
                            <img src={Still5} />
                        </div>
                        <div className='col-sm-4'>
                            <img src={Still6} />
                        </div>
                    </div>

                    <div className="stills row">
                        <div className='col-sm-4'>
                            <img src={Still7} />
                        </div>
                        <div className='col-sm-4'>
                            <img src={Still8} />
                        </div>
                        <div className='col-sm-4'>
                            <img src={Still9} />
                        </div>
                    </div>


                </div>
            </div> */}


        </div>
    )
}

export default Trailer;