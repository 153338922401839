import './News.css'

import Poster from './assets/poster-text.jpg'

const News = () =>{
    return(
        <div className='container' id="news">
            <div className="row">
                <p className='glow'>Transformers: Terminal is currently in Post-Production. <a href='http://instagram.com/transformersterminal' target='_blank'>
                    
                    Follow us on Instagram</a> and Check back for updates soon :)</p>
                <img src={Poster}/>
            
            </div>
        </div>
    )
}

export default News;