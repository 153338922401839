import React from "react";

import './Landing.css'
import Chatroom from "./Chatroom";


import Fanart from './assets/fanart.jpg'
import Terminal from './assets/logo-white-final.png';

const Landing = () => {
    return (
        <div id='landing'>
            <div className="container chatroom-frame">

                <div className="row">
                    <div className="col topbar">
                        <p>Chat from Sierrrra__</p>
                    </div>
                </div>

                <div className='row chatroom-window'>

                    <div className='col-md-7 chatroom-screen'>
                        <Chatroom />
                    </div>

                    <div className="col-md-5">
                        <div className="terminal">
                            <img src={Terminal}/>
                        </div>
                        <div className="sierra-profile">
                        <div className="sierra-chat-pic">
                            <img src={Fanart} />
                        </div>
                        <div className="sierra-info">
                            <p>
                            <span className='sierra-profile-headers'>USERNAME:</span><br/>Sierrrra__
                            </p>
                            <p>
                                <span className='sierra-profile-headers'>MUTUAL SERVERS:</span><br />
                                New Models<br />
                                Do Not Research<br />
                                Exclusive Moves HQ

                            </p>
                        </div>
                        </div>


                    </div>
                </div>



            </div>
        </div>
    )
}

export default Landing;