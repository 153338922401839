import {Outlet} from 'react-router-dom'

import Landing from './Landing';
import Navbuttons from './components/Navbuttons';
import Footer from './components/Footer';

import './App.css';

function App() {
  return (
    <div className="App">
      <Navbuttons/>
      <Outlet/>
      <Footer/>
    </div>
  );
}

export default App;
