import React from "react";

import './Letter.css'

const Letter = () => {
    return (
        <div id='Letter' className="container">

            <div className="row notes">
                NOTES: idk if u want this to have its own page or what but who give a fuck lets make it cute lol
                <br /><br />
                <span className="holly">- Holly</span>
            </div>

            <div className="row special-letter">
            <p><em>Letter goes here:</em></p>
            <p>&#9825;&#9825;&#9825;&#9825;&#9825;Dear Audience&#9825;&#9825;&#9825;&#9825;&#9825;,</p>
            <p>I love you So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo 
            </p>
            <p>So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo </p>

            <p>So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo So so so so so so so so so so so so 
            So so so so so so so so so so so s000
            SoooSO sososososo much. </p>
            <p>
                love, <br/>
                &#9825;&#9825;&#9825;&#9825;&#9825;TomiMiles&#9825;&#9825;&#9825;&#9825;&#9825;<br/>
                <span style={{fontSize:`small`}}>and the cast and crew of transformers i guess....</span> &#129298;

            </p>
            </div>

        </div>
    )
}

export default Letter;