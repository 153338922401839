import React from "react";
import Miles from './assets/miles-new-headshot.JPG';
import Tomi from './assets/tomiupdatedpic.png';

import './CastCrew.css'


const milestext = 'Miles Engel Hawbecker is a writer/director from Baltimore, Maryland. His work explores horror, altered states, media culture, and the body. He has shown in galleries and theaters in Lagos, British Columbia, Miami, New York, Philadelphia, and Baltimore. When not on set, he works as a film teacher at Baltimore School for the Arts and as a theater manager at the historic Senator Theater. He is currently writing his next feature film.'
const tomitext = 'Tomi Faison is a multidisciplinary artist and filmmaker from Maryland. Her work is interested in systems, politics, psychoanalysis, and internet culture. She has participated in group shows and screenings in Baltimore, DC, Frederick, Holyoke, Ann Arbor, Chicago, and New York. Her solo show, Phase Change, at the Frederick Arts Council in 2019 was a year long, three part, exploration of change and difference articulated through the phases of the hydraulic cycle. In 2020, she was a founding curator and technical director of a public access style 24/7 live stream project called QuaranTV, which streamed non-stop for the first 2000 hours of the pandemic and was listed as a part of Wire Magazine’s best of 2020. In 2021 she was a founding member of online arts, politics, and research community and publishing platform Do Not Research, where she now serves as director of audio-visual programming. She lives and works in Baltimore, MD with her partner where she likes to drive her ‘94 Ford Ranger a little too fast.'

const BioSection = (props) => {
    return (
        <div className="container bio-div">

            <div className="row">
                <div className="col-12 bio-title">
                    <h3>{props.name}</h3>
                    <p>{props.title}</p>
                    <a href={props.linkdest} target='_blank'><p>Link</p></a>
                </div>
            </div>
            <div className="row">

                <div className="col-lg-3 bio-pic">
                    <img src={props.img} />
                </div>

                <div className="col-lg-9 bio-text">
                    <p>{props.text}</p>

                </div>
            </div>


        </div>
    )
}

const Filmmakers = () => {
    return (
        <div id='TomiMiles'>
            <div className="container">
            <div className="row notes">
                NOTES: obv this is fake or whatever but whatever sorry again about the retarded fonts will changge later rofl
                <br /><br />
                <span className="holly">- Miss Queen Holly</span>
            </div>
            </div>
            <BioSection name='Miles Engel-Hawbecker' title='Writer/Director' img={Miles} text={milestext} linkdest='https://www.youtube.com/user/scuzzcrow' />
            <BioSection name='Tomi Faison' img={Tomi} title='Co-Writer/Producer' text={tomitext} linkdest='https://vimeo.com/tomifaison' />


            <div className="container bio-div">
                <div className="row crew-row">
                <h4>Additional Crew</h4>
                <ul className="additional-crew">
                    <li><span className="crew-role">Director of Photography: </span><span className="dotdotdot">............ </span><span className="crew-name">Nick Gorey</span></li>
                    <li><span className="crew-role">Associate Producers: </span><span className="dotdotdot">............ </span><span className="crew-name">Katherine Murphy & Laura Gede</span></li>
                    <li><span className="crew-role">1st Assistant Director: </span><span className="dotdotdot">............ </span><span className="crew-name">Kayla Drzewicki</span></li>
                    <li><span className="crew-role">Score: </span><span className="dotdotdot">............ </span><span className="crew-name">Joe DeGeorge</span></li>
                    <li><span className="crew-role">Additional Score: </span><span className="dotdotdot">............ </span><span className="crew-name">Lil Internet</span></li>
                    <li><span className="crew-role">Gaffer: </span><span className="dotdotdot">............ </span><span className="crew-name">Devin McBay</span></li>
                    <li><span className="crew-role">Location & Post Sound: </span><span className="dotdotdot">............ </span><span className="crew-name">Jason Charney</span></li>
                    <li><span className="crew-role">Hair and Makeup: </span><span className="dotdotdot">............ </span><span className="crew-name">Liz Spagnolo</span></li>
                    <li><span className="crew-role">SFX Designer: </span><span className="dotdotdot">............ </span><span className="crew-name">Harris Rosenblum</span></li>
                    <li><span className="crew-role">Website and Fan Art: </span><span className="dotdotdot">............ </span><span className="crew-name">Holly Oliver</span></li>

                </ul>
            </div>
                </div>
                
            </div>



    )
}

export default Filmmakers;