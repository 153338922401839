import './Contact.css';

import Growing from './components/assets/icons/growing-heart.png';

import Fanart from './assets/fanart.jpg';

const Contact = () => {
    return (
        <div id='Contact'>
            <div className="container">
                <div className="row">
                    <div className="col">

                        <div className='contact-item-div'>
                            <img src={Growing}/>
                            <p>Please contact <span className='contact-link'>TransformersTerminal@gmail.com</span> for more info or materials</p>

                        </div>



                        <div className='contact-item-div'>
                        <img src={Growing}/>

                            <p>Instagram: <a className='contact-link' href='http://instagram.com/transformersterminal' target='_blank'>@transformersterminal</a></p>

                        </div>



                    </div>
                </div>

                <div className='row'>
                    <div className='col'>
                        <img src={Fanart} className='contact-fanart' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact;