import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    HashRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import './index.css';

import App from './App';
import Info from './Info';
import Landing from './Landing';
import Cast from './Cast';
import Filmmakers from './Filmmakers';
import Letter from './Letter';
import News from './News.js'
import Contact from './Contact';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>

  <Routes>
      <Route path="/" element={<App />}>
          <Route path="/" element={<Navigate to="/Landing" />} />
          <Route path='Landing' element={<Landing />} />
          <Route path='Cast' element={<Cast />} />
          <Route path='Letter' element={<Letter />} />
          <Route path='Info' element={<Info />} />
          <Route path='Filmmakers' element={<Filmmakers />} />
          <Route path='News' element={<News />} />
          <Route path='Contact' element={<Contact />} />


      </Route>

  </Routes>
</HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
