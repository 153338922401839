import React from "react";
import {NavLink} from 'react-router-dom'

const Button = (props) => {
    const buttonimage = props.buttonimage;
    const dest = props.dest;
    const text = props.text
    return (
        <li>
            <div className="button">

                <NavLink to ={dest}>
                    <img className='button-image' src={buttonimage} />
                    <p className="button-text">{text}</p>
                    
                </NavLink>
            </div>
        </li>
    )
}

export default Button;
