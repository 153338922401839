import React from "react";
import './Footer.css';

const Footer = () =>{
    return(
        <div id='Footer' className="mt-auto">
            <div className="fake-disclaimer">
                <p></p>
            </div>
        </div>
    )
}

export default Footer;